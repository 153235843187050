<template>
  <div>
    <el-button type='text' icon='el-icon-refresh' @click='getStatList' style='font-size: 20px;font-weight: bold'
               :loading='loading'>API调用统计
    </el-button>
    <div>
      <div><b>统计时间：</b>{{ curr_time }}</div>
      <el-table :data='dataList' height='500' :default-sort = "{prop: 'total', order: 'descending'}">
        <el-table-column label='序号' type='index' width='55'></el-table-column>
        <el-table-column label='URI' prop='path' width='380'></el-table-column>
        <el-table-column label='调用次数' prop='total' width='160' align='right' sortable></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { statApiCallLogs } from '@/api/sys/api_call_log'

export default {
  name: 'ApiCallStat',
  data() {
    return {
      loading: false,
      dataList: [],
      curr_time: null
    }
  },
  methods: {
    async getStatList() {
      this.dataList = []
      this.loading = true
      let { data } = await statApiCallLogs()
      this.dataList = data.list || []
      this.curr_time = data.curr_time
      setTimeout(() => {
        this.loading = false
      }, 500)
    }
  },
  created() {
    this.getStatList()
  }
}
</script>

<style scoped>

</style>
