import * as request from '@/utils/request'

/**
 * 自动生成 api代码【模块名：API调用日志】
 * 生成日期：2025/04/03 11:45:13
 * 生成路径: src/api/api_call_log.js
 * 生成人：超级管理员
 */

/**
 * @description 获取列表
 * @param {*} data
 * @returns
 */
export function getApiCallLogList(data) {
  return request.post('/api_call_log/list', data)
}

/**
 * @description 保存
 * @param {*} data
 * @returns
 */
export function saveApiCallLog(data) {
  return request.post('/api_call_log/store', data)
}

/**
 * @description 获取详情
 * @param id
 * @returns
 */
export function getApiCallLogInfo(id) {
  let data = { id: id }
  return request.post('/api_call_log/get_info', data)
}

/**
 * @description 获取详情
 * @param where
 * @returns
 */
export function queryApiCallLogInfo(where) {
  return request.post('/api_call_log/query', where)
}

/**
 * @description 根据id删除单条信息
 * @param id
 * @returns
 */
export function deleteApiCallLog(id) {
  let data = { id: id }
  return request.post('/api_call_log/delete', data)
}

/**
 * 导入
 * @param data
 * @returns {Promise}
 */
export function importApiCallLogs(data) {
  return request.post('/api_call_log/import', data)
}

/**
 * 导出
 * @param data
 * @returns {Promise}
 */
export function exportApiCallLogs(data) {
  return request.postBlob('/api_call_log/export', data)
}

/**
 * 统计
 * @param data
 * @returns {Promise}
 */
export function statApiCallLogs(data) {
  return request.postBlob('/api_call_log/stat', data)
}
